<template>
  <div class="view-container">
    <iframe :src="src">预览图文消息</iframe>
  </div>
</template>

<script>
import { PCN_H5_URL } from "@/config";
export default {
  data() {
    return {
      src: ""
    };
  },
  created() {
    this.src = `${PCN_H5_URL}/pages/message/detail?id=${this.$route.query.id}`;
  }
};
</script>

<style scoped lang="scss">
.view-container {
  width: 750px;
  height: calc(100vh - 100px);
  margin: 0 auto;
  padding: 20px 50px;
  background: #fff;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
