var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-container"
  }, [_c('iframe', {
    attrs: {
      "src": _vm.src
    }
  }, [_vm._v("预览图文消息")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }